* {
  margin: 0;
  padding: 0;
}

/* elements */

body {
  font-family: "Menlo", sans-serif;
  /* add a scrollbar to every page (either needed or not) */
  overflow-y: scroll;
}

button {
  background-color: transparent;
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 32px;
  text-align: center;
  cursor: pointer;
  padding: 8px 12px;
  margin: 5px;
}

button:focus {
  outline: none;
  box-shadow: 0 0 2px 0px #ff6b48 inset;
  opacity: 0.9;
  /* color: #ffcc00; */
}

footer {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* padding: 10px; */
  border-top: 1px dotted #bbb;
}

header {
  display: flex;
  width: 100%;
  position: fixed;
  z-index: 200;
  opacity: 0.89;
  mix-blend-mode: luminosity;
  /* luminosity, exclusion/difference, overlay, saturation */
  /* check in Safari as it renders differently */
}

input,
textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 8px;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: 0 0 2px 0px #fc0 inset;
}

select {
  border: 1px solid teal;
  padding: 2px 3px;
  background-color: #fefefe;
}

select:focus {
  outline: none;
}

ul {
  list-style-type: none;
}

/* wrappers */

.outer {
  /* this would truncate the element with visible effect on background */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.var-wrapper {
  /* width: 60vw; */
  /* border: 1px dashed #eee;  */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.row,
.row-col,
.row-rev {
  display: flex;
  flex-direction: row;
}

.col,
.col-row {
  display: flex;
  flex-direction: column;
}

/* alignments */
.jc-start {
  justify-content: flex-start;
}

.jc-center {
  justify-content: center;
}

.jc-end {
  justify-content: flex-end;
}

.jc-between {
  justify-content: space-between;
}

.ai-start {
  align-items: flex-start;
}

.ai-center {
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}

.as-start {
  align-self: left;
}

.as-center {
  align-self: center;
}

.as-end {
  align-self: right;
}

.ta-start {
  text-align: left;
}

.ta-center {
  text-align: center;
}

.ta-end {
  text-align: right;
}

/* grids */

.cell,
.cell-rev {
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell02 {
  /* border: 1px solid #dfdfdf; */
  /* border-radius: 8px; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.gr00 {
  display: grid;
  width: 80%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 200px;
  grid-gap: 16px;
}

.gr01 {
  display: grid;
  width: 80%;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-auto-rows: 160px;
  grid-gap: 16px;
}

.gr01lo {
  display: grid;
  width: 80%;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-auto-rows: 80px;
  grid-gap: 16px;
}

.gr02 {
  display: grid;
  width: 88%;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  /* grid-auto-rows: 50%; */
  grid-gap: 12px;
  /* border: 1px #dfdfdf dotted;
  border-radius: 8px; */
}

/* shapes */

.cerchio {
  border: 0px solid black;
  /* background-color: #ffcc00; */
  border-radius: 50%;
  /* prevent 'size calculation glitch' in chrome */
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  /* 74 to 80 */
}

/* animations */

@keyframes pulsation {
  0% {
    transform: scale(1);
    opacity: 0.92;
  }
  50% {
    transform: scale(1.25);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.92;
  }
}

.rotator {
  /* animation: App-logo-spin infinite 10s linear; */
  /* height: 40vmin; */
  pointer-events: none;
  animation-name: rotation;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  will-change: transform;
}

@keyframes rotation {
  0% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(330deg);
  }
}

@keyframes rotation-2w {
  0% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(330deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}

/* change only duration property */

.pulse {
  animation-name: pulsation;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  will-change: transform;
}

.s1 {
  animation-duration: 1s;
}

.s2 {
  animation-duration: 2s;
}

.s4 {
  animation-duration: 4s;
}

.s8 {
  animation-duration: 8s;
}

/* more animations while setting up CSS modules */

.spinFaster {
  animation: spin 0.8s linear infinite;
}

.spinSlower {
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blinkFaster {
  animation: blinkMore 0.8s infinite;
}
.blinkSlower {
  animation: blinkMore 1.2s infinite;
}
.blinkWeaker {
  animation: blinkLess 1.6s infinite;
}
.blinkPartial {
  animation: blinkStaying 1.6s infinite;
}

@keyframes blinkMore {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blinkLess {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blinkStaying {
  0% {
    opacity: 0.35;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.35;
  }
}

/* links */

.a1 {
  text-decoration: underline;
  color: slategrey;
}

.a1:hover {
  text-decoration: none;
  color: #ff5000;
}

.a2 {
  text-decoration: underline;
  color: #ff8000;
}

.a2:hover {
  text-decoration: none;
  color: #ff5000;
}

.a3 {
  text-decoration: underline;
  color: black;
}

.a3:hover {
  text-decoration: none;
  color: red;
}

/* modifiers */

.wh16 {
  width: 16px;
  height: 16px;
}

.wh20 {
  width: 20px;
  height: 20px;
}

.wh40 {
  width: 40px;
  height: 40px;
}

.wh60 {
  width: 60px;
  height: 60px;
}

.wh80 {
  width: 80px;
  height: 80px;
}

.wh100 {
  width: 100px;
  height: 100px;
}

.wh120 {
  width: 120px;
  height: 120px;
}

.w70vw {
  width: 70vw;
}

.w65vw {
  width: 65vw;
}

.w60vw {
  width: 60vw;
}

.w55vw {
  width: 55vw;
}

.w50vw {
  width: 50vw;
}

.w40vw {
  width: 40vw;
}

.w35vw {
  width: 35vw;
}

.w30vw {
  width: 30vw;
}

.w25vw {
  width: 25vw;
}

.w20vw {
  width: 20vw;
}

.w15vw {
  width: 15vw;
}

.w12vw {
  width: 12vw;
}

.w10vw {
  width: 10vw;
}

.w120pc {
  width: 120%;
}

.w100pc {
  width: 100%;
}

.w90pc {
  width: 90%;
}

.w80pc {
  width: 80%;
}

.w66pc {
  width: 66%;
}

.w50pc {
  width: 50%;
}

.w33pc {
  width: 33.3%;
}

.w20pc {
  width: 20%;
}

.w160 {
  width: 160px;
}

.w100 {
  width: 100px;
}

.w80 {
  width: 80px;
}

.w30 {
  width: 30px;
}

.w24 {
  width: 24px;
}

.w20 {
  width: 20px;
}

.h26 {
  height: 26px;
}

.mar-3 {
  margin: 3px;
}

.mar-5 {
  margin: 5px;
}

.mar-10 {
  margin: 10px;
}

.mar-15 {
  margin: 15px;
}

.mar-20 {
  margin: 20px;
}

.pad-3 {
  padding: 3px;
}

.pad-5 {
  padding: 5px;
}

.pad-10 {
  padding: 10px;
}

.pad-15 {
  padding: 15px;
}

.pad-20 {
  padding: 20px;
}

.border-c {
  border: 1px solid #ccc;
}

.border-1 {
  border: 1px dashed #ddd;
}

.border-2 {
  border: 2px dashed #ddd;
}

.radius-4 {
  border-radius: 4px;
}

.radius-8 {
  border-radius: 8px;
}

.txt-l {
  text-align: left;
}

.txt-c {
  text-align: center;
}

.txt-r {
  text-align: right;
}

.txt-18 {
  font-size: 18px;
}

.txt-16 {
  font-size: 16px;
}

.txt-15 {
  font-size: 15px;
}

.txt-14 {
  font-size: 14px;
}

.txt-12 {
  font-size: 12px;
}

.txt-11 {
  font-size: 11px;
}

.txt-10 {
  font-size: 10px;
}

.txt-b {
  font-weight: bold;
}

.space-04 {
  letter-spacing: 0.04rem;
}

.space-08 {
  letter-spacing: 0.08rem;
}

.space-25 {
  letter-spacing: 0.25rem;
}

.line-height-125 {
  line-height: 125%;
}

.line-height-150 {
  line-height: 150%;
}

.line-height-175 {
  line-height: 175%;
}

.col-white {
  color: white;
}

.col-fc0 {
  color: #fc0;
}

.col-orangered {
  color: orangered;
}

.col-red {
  color: red;
}

.col-teal {
  color: teal;
}

.col-slategrey {
  color: slategrey;
}

.col-mus-red {
  color: #ff6b48;
}

.col-mus-grey {
  color: #bac5c3;
}

.col-mus-blue {
  color: #83bad6;
}

.col-2e8 {
  color: #2e86c1;
}

.col-349 {
  color: #3498db;
}

.col-dsb {
  color: darkslateblue;
}

.bg-mus-blue {
  background-color: #83bad6;
}

.bg-349 {
  background-color: #3498db;
}

.bg-2e8 {
  background-color: #2e86c1;
}

.bg-fc0 {
  background-color: rgba(255, 204, 0, 0.36);
}

.bg-red {
  background-color: red;
}

.bg-dsb {
  background-color: darkslateblue;
}

.bg-sb {
  background-color: slateblue;
}

.bg-sg {
  background-color: slategrey;
}

.bg-0 {
  background-color: #000;
}

.bg-1 {
  background-color: #111;
}

.bg-2 {
  background-color: #222;
}

.bg-3 {
  background-color: #333;
}

.bg-4 {
  background-color: #444;
}

.bg-5 {
  background-color: #555;
}

.bg-6 {
  background-color: #666;
}

.bg-7 {
  background-color: #777;
}

.bg-8 {
  background-color: #888;
}

.bg-9 {
  background-color: #999;
}

.bg-a {
  background-color: #aaa;
}

.bg-b {
  background-color: #bbb;
}

.bg-c {
  background-color: #ccc;
}

.bg-d {
  background-color: #ddd;
}

.bg-e {
  background-color: #eee;
}

.bg-f {
  background-color: #fff;
}

.bg-grad-e {
  background: linear-gradient(0deg, #eee, #fff 100%);
}

.bg-grad-fc0 {
  background: linear-gradient(
    90deg,
    rgba(255, 204, 0, 0.4),
    rgba(0, 0, 0, 0) 100%
  );
}

.bg-grad-f80 {
  background: linear-gradient(
    90deg,
    rgba(255, 136, 0, 0.5),
    rgba(0, 0, 0, 0) 100%
  );
}

.bg-grad-gr2 {
  background: linear-gradient(
    90deg,
    rgba(46, 204, 113, 0.6),
    rgba(0, 0, 0, 0) 100%
  );
}

.bg-grad_fc0 {
  background: linear-gradient(
    135deg,
    rgba(255, 204, 0, 0.4),
    rgba(0, 0, 0, 0) 100%
  );
}

.bg-grad_f80 {
  background: linear-gradient(
    135deg,
    rgba(255, 136, 0, 0.5),
    rgba(0, 0, 0, 0) 100%
  );
}

.bg-grad_gr2 {
  background: linear-gradient(
    135deg,
    rgba(46, 204, 113, 0.6),
    rgba(0, 0, 0, 0) 100%
  );
}

.bg-grad_f80-fc0 {
  background: linear-gradient(
    135deg,
    rgba(255, 136, 0, 0.5),
    rgba(255, 204, 0, 0.4) 100%
  );
}

.bg-grad_gr2-fc0 {
  background: linear-gradient(
    135deg,
    rgba(46, 204, 113, 0.6),
    rgba(255, 204, 0, 0.4) 100%
  );
}

.bg-grad_fc0-var {
  background: linear-gradient(
    135deg,
    rgba(255, 204, 0, 0.5),
    rgba(255, 255, 0, 0.4) 100%
  );
}

.opacity-15 {
  opacity: 0.15;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-45 {
  opacity: 0.45;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-90 {
  opacity: 0.9;
}

.shadow-fc0 {
  opacity: 0.92;
  filter: drop-shadow(0px 1px 1px #fc0);
}

.shadow-ddd {
  opacity: 0.92;
  filter: drop-shadow(0px 1px 1px #ddd);
}

.shadow-ccc {
  opacity: 0.92;
  filter: drop-shadow(0px 1px 1px #ccc);
}

.shadow-bbb {
  opacity: 0.92;
  filter: drop-shadow(0px 1px 1px #bbb);
}

.point {
  cursor: pointer;
}

/* form */

.content-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-button {
  padding: 8px 8px;
  /* width: 14vw;    */
  text-align: center;
  font-weight: bold;
}

/* overlays (and item) */

.overlay-tl {
  position: absolute;
  top: 20px;
  left: 25px;
  opacity: 0.93;
}

.overlay-tr {
  position: absolute;
  top: 20px;
  right: 11%;
  opacity: 0.93;
}

.hook {
  position: relative;
  /* margin: auto; */
  overflow: hidden; /* keep overflow invisible if out of the area */
}

.overlay {
  transition: top ease 1s; /* with devtools you can change the style of animation, and test its speed */
  position: absolute; /* floating around the screen, will look for an item (the closest one) with relative positioning */
  top: -100px; /*out of the way*/
  opacity: 0;
  left: 10%; /*temp solution*/
  /* width: 80%; */
  /* background-color: #111111; */
}

.hook:hover .overlay {
  /* on hover, affect overlay class */
  top: 0;
  opacity: 0.95;
}

/* media queries */
/* NB: the viewport line in index.html is affecting these */

@media only screen and (max-width: 600px) {
  .row-col {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .var-wrapper {
    align-items: center;
    justify-content: center;
  }

  .row-rev {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .col-row {
    flex-direction: row;
  }

  .cell-rev {
    flex-direction: column-reverse;
    /* width: "100%"; */
  }

  .no-display {
    display: none;
  }

  .form-button {
    width: 98px;
  }

  .cerchio {
    /* 52 to 60 */
    width: 52px;
    height: 52px;
  }
}

/* device-specific  media queries */

/* iphone 3 */
@media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 1) {
}

/* iphone 4 */
@media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 2) {
}

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
}

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
}

/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
}

/* iphone X , XS, 11 Pro, 12 Mini */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
}

/* iphone 12, 12 Pro */
@media only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
}

/* iphone XR, 11 */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
}

/* iphone XS Max, 11 Pro Max */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
}

/* iphone 12 Pro Max */
@media only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
}
